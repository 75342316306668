import { defineStore } from 'pinia';
export const useUnitStore = defineStore({
    persist: true,
    id: 'unitPage',
    state: () => ({
        unit: {},
        users: {},
        groups: {},
        nextUnit: {},
        prevUnit: {},
    })
});
