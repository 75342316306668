<template>
  <div
    :id="'course-' + course.id"
    class="bg-white w-full border-r-4 border-untitled-gray-300 px-4 pt-5 pb-8 shadow-md mb-2"
  >
    <div class="mb-3 md:flex items-center justify-between">
      <h3 class="font-bold sm:w-2/5 text-2xl text-untitled-gray-700">
        {{ course.name }}
      </h3>
      <ul class="md:flex items-center justify-between md:pl-6">
        <li
          class="text-untitled-gray-600 flex items-center text-xs md:mr-2 my-2"
        >
          <ClockIcon class="h-5 w-5 mr-2" />
          <!-- <span class="mx-2">|</span> -->
          <p>{{ course.course_duration }}</p>
        </li>
        <li
          class="text-untitled-gray-600 flex items-center text-xs md:mr-2 my-2"
        >
          <VideoCameraIcon class="h-5 w-5 mr-2" />
          <router-link :to="'/units/' + course.unit_display.id">
            <!-- <span class="mx-2">|</span> -->
            <span class="font-sans"
              >{{ course.course_details.units_video_count }} Videos</span
            >
          </router-link>
        </li>
        <li
          class="text-untitled-gray-600 flex items-center text-xs md:mr-2 my-2"
        >
          <DocumentTextIcon class="h-5 w-5 mr-2" />
          <router-link :to="'/units/' + course.unit_display.id">
            <!-- <span class="mx-2">|</span> -->
            <span class="font-sans"
              >{{ course.course_details.units_text_count }} Text Units</span
            >
          </router-link>
        </li>
        <li
          v-if="course.assigned_course"
          @mouseenter="showToolTip = true"
          @mouseleave="showToolTip = false"
          class="cursor-pointer relative text-untitled-gray-600 flex items-center text-xs"
        >
          <CalendarIcon class="h-5 w-5 mr-2" />
          <!-- <span class="mx-2">|</span> -->
          <p>{{ formatDate(course.assigned_course.due_date, "MM/DD/YYYY") }}</p>
          <span
            v-if="showToolTip"
            class="absolute bg-untitled-gray-200 text-untitled-gray-500 rounded p-2 left-0 top-[-30px] lg:top-[20px] md:top-[20px] z-10"
          >
            Expected date and time of completion of the course
          </span>
        </li>
      </ul>
    </div>
    <div class="md:flex items-center">
      <div class="md:w-full">
        <router-link :to="'/units/' + course.unit_display.id">
          <div class="relative cursor-pointer">
            <img
              v-lazy="{
                src: course.unit_display.thumbnail,
                error:
                  'https://lms-v2-staging.s3-us-west-2.amazonaws.com/modules/default-thumbnail.jpg',
                loading:
                  'https://via.placeholder.com/800x450?text=Thumbnail%20Loading...',
              }"
              class="object-scale-down aspect-video md:w-[60%]"
            />

            <div
              class="absolute md:w-[60%] bottom-0 h-full object-center inset-0 place-items-center bg-gradient-to-t from-black opacity-50 text-white text-xs text-center leading-4"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="mt-[18%] object-center place-items-center inset-0 mx-auto w-12 h-12"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                />
              </svg>
            </div>
          </div>

          <div class="flex items-center">
            <h4
              class="text-untitled-gray-800 font-medium mt-2 text-lg"
            >
              {{ course.unit_display.name }}
            </h4>
            <PlayIcon class="h-5 w-5 ml-2 mt-2" />
          </div>
        </router-link>
      </div>
      <div class="sm:w-32 md:pl-6 md:w-full">
        <div class="container mx-auto">
          <div class="w-full mx-auto mt-5 md:mt-5">
            <div class="h-1 bg-untitled-gray-200 rounded">
              <div
                class="bg-untitled-gray-400 h-1 rounded relative"
                :style="'width: ' + course.completed_percentage + '%'"
              ></div>
            </div>
            <div class="flex lg:justify-end md:justify-start items-center pt-5">
              <p class="text-xs text-untitled-gray-500 font-bold">
                {{ course.completed_percentage }}% Complete
              </p>
            </div>
            <div class="flex lg:justify-end md:justify-start items-center pt-1 mt-5">
              <button
                @click="toggleAssignCourse"
                class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-untitled-gray-600 bg-untitled-gray-200 hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500 mr-2"
              >
                Assign Course
              </button>
              <router-link
                :to="'/units/' + course.unit_display.id"
                class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-untitled-gray-600 bg-untitled-gray-200 hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500"
              >
                {{
                  course.completed_percentage >= 100
                    ? "Retake Course"
                    : course.completed_percentage > 0
                    ? "Continue Learning"
                    : "Start Learning"
                }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Assign Course -->
  <NotificationDialog
            v-if="showNotificationDialog"
            :content="message"
            @close="closeNotificationDialog"
            :close-dialog="showNotificationDialog"
          />
  <TransitionRoot appear :show="open" as="template">
    <Dialog as="div" class="relative z-[1000]">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 h-3/6">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="relative w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
            >
              <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                <button
                  @click="open = false"
                  type="button"
                  class="rounded-md bg-white text-untitled-gray-400 hover:text-untitled-gray-500"
                >
                  <span class="sr-only">Close</span>
                  <XIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 text-untitled-gray-900"
              >
                Assign {{ course.name }} Course
              </DialogTitle>

              <div class="mt-2">
                <AssignUser
                  @assign="getAssignedUser"
                  @createGroup="handleClickCreateGroup"
                  :close="
                    () => {
                      open = false;
                    }
                  "
                />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <GroupFormModal :eventId="groupFormModalEventId" />
</template>
<script setup>
import {
  VideoCameraIcon,
  ClockIcon,
  DocumentTextIcon,
  CalendarIcon,
  XIcon,
  PlayIcon,
} from "@heroicons/vue/outline";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import AssignUser from "@/components/course/unit/partials/forms/AssignUser.vue";
import NotificationDialog from "@/components/partials/NotificationDialog.vue";
import GroupFormModal from '@/components/groups/GroupFormModal';
import EventBus from '@/plugins/eventBus';
import dayjs from "dayjs";
import Bugsnag from "@bugsnag/js";
import { ref, inject, defineProps } from 'vue'

const props = defineProps({
    course: {
      type: Object,
      required: true,
    },
})

const axios = inject('axios');

const groupFormModalEventId = `assignCourse${props.course.id}`;

const open = ref(false);
const assign = ref(null);
const showNotificationDialog = ref(false);
const message = ref(null);
const showToolTip = ref(false);


const handleClickCreateGroup = () => {
  EventBus.$emit(`openGroupFormModal${groupFormModalEventId}`);
}

const toggleAssignCourse = () => {
  open.value = !open.value;
};

const getAssignedUser = (value) => {
  if (value) {
    assign.value = value;
    handleSubmit();
  }
};

const formatDate = (value, format) => {
  return dayjs(value).format(format);
};

const handleSubmit = () => {

  if (assign?.value.assigned_users?.length == 0 && assign?.value.assign_groups?.length == 0
  ) {
    message.value = "Please select a user to assign this course";
    showNotificationDialog.value = true;
    return;
  }

  axios
    .post("/api/v3/courses/assign", {
      course_id: props.course.id,
      users_id: assign?.value.assigned_users,
      groups_id: assign?.value.assigned_groups,
      due_date: assign?.value.due_date,
    })
    .then(() => {
      EventBus.$emit('REFRESH_NOTIFICATION')
      showNotificationDialog.value = true;
      message.value = "Course assigned successfully";
      open.value = false;
    })
    .catch((error) => {
      Bugsnag.notify(error);
    });
};

const closeNotificationDialog = () => {
  showNotificationDialog.value = false;
};
</script>
