import dayjs from "dayjs";

export const postPayload = (
        event, 
        selectedAssignees = [], 
        selectedDealers = [], 
        selectedCompanies = [],
        dateRange = []
    ) => {
        const dateFormat = "YYYY-MM-DD HH:mm:ss";
        const tempEvent = structuredClone(event);
        if(typeof dateRange[0] !== 'string' ) {
            tempEvent.start_at = null
            tempEvent.end_at = null
        }
        else {
            tempEvent.start_at = dayjs(dateRange[0]).format(dateFormat);
            tempEvent.end_at = dayjs(dateRange[1]).format(dateFormat);
        }

        tempEvent.assignees = { ...selectedAssignees };
        tempEvent.dealers = selectedDealers;
        tempEvent.companies = selectedCompanies;

        return tempEvent;
}