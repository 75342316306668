<script setup>
import { defineProps, ref, onUnmounted } from "vue";

import ECombobox from "@/components/element-components/ECombobox"
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";

import { VButton } from 'revdojo-vue-components'
import EventBus from "@/plugins/eventBus";
import { postReminderPayload } from "@/composable/reminder/reminder-payload";
import { postReminder } from "@/composable/reminder/post-reminder";
import TimezoneSelector from "@/components/utilities/TimezoneSelector.vue";
import EDatePicker from "@/components/element-components/EDatePicker.vue";

const props = defineProps({
  isOpen: {
    default: false,
    type: Boolean,
  },
  handleCloseModal: {
    type: Function,
  },
  users: {
    default: [],
  }
});

const reminder = ref({
  name: "",
  description: "",
  date: null,
  time: null,
  message: "",
  timezone: null,
});

const selectedUsers = ref([]);
const errorData = ref(null)
const isLoading = ref(false);
const errors = ref({
  name: { fieldName: "name", error: null },
  description: { fieldName: "description", error: null },
  date: { fieldName: "date", error: null },
  time: { fieldName: "time", error: null },
  timezone: { fieldName: "timezone", error: null },
  assignUsers: { fieldName: "assignUsers", error: null },
});

const closeModal = () => {
  props.handleCloseModal();
};
const isValid = () => {
  let valid = true;

  errors.value = {
    name: { fieldName: "name", error: null },
    description: { fieldName: "description", error: null },
    date: { fieldName: "date", error: null },
    time: { fieldName: "time", error: null },
    timezone: { fieldName: "timezone", error: null },
    assignUsers: { fieldName: "assignUsers", error: null },
  };

  if (!reminder.value.name.length) {
    valid = false;
    errors.value.name.error = "Name is required";
  }

  if (!reminder.value.description.length) {
    valid = false;
    errors.value.description.error = "Description is required";
  }

  if (!reminder.value.date) {
    valid = false;
    errors.value.date.error = "Date is required";
  }

  return valid;
};

const saveGoal = async () => {
  if (!isValid()) {
    return;
  }

  isLoading.value = true;
  const payload = postReminderPayload(reminder.value, selectedUsers.value);
  const { data, isError, errorValue } = await postReminder(payload)

  if (isError && errorValue.value) {
    errorData.value = errorValue.value
    return;
  }

  if (data && data.id) {
    EventBus.$emit("NEW_REMINDER", data);
  }

  closeModal();
  isLoading.value = false;
};

onUnmounted(() => {
  reminder.value = {
    name: "",
    description: "",
    date: null,
    time: null,
    message: "",
    timezone: null,
  }
  selectedUsers.value = []
})

</script>

<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-[999]">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
        leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex items-center justify-center p-4 h-full">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">
            <DialogPanel
              class="relative w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">
                New Reminder
              </DialogTitle>
              <div class="mt-5 flex flex-col gap-3 overflow-  y h-full">
                <div>
                  <label class="mb-2 text-sm">Name</label>
                  <input v-model="reminder.name" type="text" placeholder="Reminder Name" required
                    class="block w-full appearance-none rounded-md border border-untitled-gray-300 px-3 py-2 placeholder-untitled-gray-400 shadow-sm focus:border-untitled-gray-500 focus:outline-none focus:ring-untitled-gray-500 sm:text-sm"
                    :disabled="isLoading" />
                  <p v-if="errors.name" class="mt-2 text-red-500 text-xs italic">
                    {{ errors.name.error }}
                  </p>
                </div>

                <div>
                  <label class="mb-2 text-sm">Description</label>
                  <textarea placeholder="Description" v-model="reminder.description"
                    class="block w-full appearance-none rounded-md border border-untitled-gray-300 px-3 py-2 placeholder-untitled-gray-400 shadow-sm focus:border-untitled-gray-500 focus:outline-none focus:ring-untitled-gray-500 sm:text-sm h-[5rem] min-h-[5rem] max-h-[5rem]"
                    :disabled="isLoading" maxlength="255">
                  </textarea>
                  <p v-if="errors.description" class="mt-2 text-red-500 text-xs italic">
                    {{ errors.description.error }}
                  </p>
                </div>
                <div>
                  <label class="mb-2 text-sm">Message</label>
                  <textarea placeholder="Message" v-model="reminder.message"
                    class="block w-full appearance-none rounded-md border border-untitled-gray-300 px-3 py-2 placeholder-untitled-gray-400 shadow-sm focus:border-untitled-gray-500 focus:outline-none focus:ring-untitled-gray-500 sm:text-sm h-[5rem] min-h-[5rem] max-h-[5rem]"
                    :disabled="isLoading" maxlength="255">
                  </textarea>
                  <p v-if="errors.message" class="mt-2 text-red-500 text-xs italic">
                    {{ errors.message.error }}
                  </p>
                </div>
                <div>
                  <label class="mb-2 text-sm">Timezone</label>
                  <TimezoneSelector v-model="reminder.timezone" />
                  <p v-if="errors.timezone" class="mt-2 text-red-500 text-xs italic">
                    {{ errors.timezone.error }}
                  </p>
                </div>

                <div class="flex flex-col xl:flex-row lg:flex-row justify-between">
                  <div class="w-full">
                    <label class="mb-2 text-sm">Schedule date</label>
                    <EDatePicker 
                      v-model="reminder.date"
                      :format="'MMM D, YYYY h:mm A'"
                      :value-format="'YYYY-MM-DD h:mm A'"
                      :read-only="isLoading"
                      :disabled-below-dates="true"
                      :placeholder="'Pick a Date and Time'"
                      :clearable="true"/>
                    <p v-if="errors.date" class="mt-2 text-red-500 text-xs italic">
                      {{ errors.date.error }}
                    </p>
                  </div>
                </div>
                <div class="flex flex-col">
                  <label class="mb-2 text-sm">Assign to users</label>
                  <e-combobox class="w-full" v-model="selectedUsers" :options="users"
                    placeholder="Start typing to search users..." value-key="id" option-name="name" :multiple="true"
                    :filterable="true" :clearable="true" collapse-tags collapse-tags-tooltip />
                  <p v-if="errors.assignUsers" class="mt-2 text-red-500 text-xs italic">
                    {{ errors.assignUsers.error }}
                  </p>
                </div>
              </div>

              <div class="mt-3 flex justify-end">
                <v-button type="button"
                  class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-untitled-gray-800 bg-untitled-gray-200 hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500"
                  @click="saveGoal" :loading="isLoading">
                  Save
                </v-button>
                <v-button type="button"
                  class="ml-2 inline-flex w-full justify-center rounded-md border border-untitled-gray-300 bg-white px-4 py-2 text-base font-medium text-untitled-gray-700 shadow-sm hover:text-untitled-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                  @click="closeModal" :loading="isLoading">
                  Cancel
                </v-button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
