import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue'
import { getRoles } from '@/composable/users/getRoles.js';

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      auth: true,
      title: 'Home'
    },
    component: HomePage
  },
  {
    path: '/reports/:tab?',
    name: 'reports',
    props: true,
    meta: {
      auth: true,
      title: 'Reports'
    },
    component: () => import('../views/ReportPage.vue')
  },
  {
    path: '/leaderboard',
    name: 'Leaderboard',
    props: false,
    meta: {
      auth: true,
      title: 'Leaderboard'
    },
    component: () => import('../views/LeaderBoard.vue')
  },
  {
    path: '/login',
    name: 'login',
    props: false,
    component: () => import('../views/AuthPage.vue')
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    props: false,
    component: () => import('../views/PasswordForgot.vue')
  },
  {
    path: '/password-reset',
    name: 'password-reset',
    props: false,
    component: () => import('../views/PasswordReset.vue')
  },

  {
    path: '/create/playlist',
    name: 'create-playlist',
    props: false,
    meta: {
      auth: true,
      title: 'Create Playlist'
    },
    component: () => import('../views/CreatePlaylistPage.vue')
  },
  {
    path: '/edit/playlist/:id',
    name: 'edit-playlist',
    props: false,
    meta: {
      auth: true,
      title: 'Edit Playlist'
    },
    component: () => import('../views/EditPlaylistPage.vue')
  },
  {
    path: '/units/:id/:playlistId?',
    name: 'units',
    props: false,
    meta: {
      auth: true,
      title: 'Unit'
    },
    component: () => import('../views/UnitPage.vue')
  },
  {
    path: '/client/lms/unit/:id',
    name: 'unitsRedirect',
    redirect: to => { return { name: 'units', params: { id: to.params.id } } }
  },
  {
    path: '/edit/unit/:id',
    name: 'edit-unit',
    props: false,
    component: () => import('../views/EditUnitPage.vue'),
    meta: {
      title: 'Edit Unit'
    }
  },
  {
    path: '/edit/unit/:id/course/:courseId?',
    name: 'edit-unit-course',
    props: false,
    component: () => import('../views/EditUnitPage.vue')
  },
  {
    path: '/reminders',
    name: 'reminders',
    meta: {
      auth: true,
      title: 'Reminders'
    },
    component: () => import('../views/ReminderPage.vue')
  },
  {
    path: '/search',
    name: 'search',
    meta: {
      auth: true,
      title: 'Search',
    },
    component: () => import('../views/SearchPage.vue')
  },
  {
    path: '/events',
    name: 'events',
    meta: {
      auth: true,
      title: 'Events'
    },
    component: () => import('../views/EventsPage.vue')
  },
  {
    path: '/my-videos',
    name: 'my-videos',
    props: false,
    meta: {
      auth: true,
      title: 'My Videos'
    },
    component: () => import('../views/MyVideosPage.vue')
  },
  {
    path: '/',
    name: 'My Profile',
    meta: {
      title: 'My Profile'
    }
  },
  {
    path: '/',
    name: 'Preference',
    meta: {
      title: 'My Preference'
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('../views/LogoutPage.vue'),
  },
  {
    path: '/*',
    name: 'PageNotFound',
    component: () => import('../views/404.vue')
  },
  {
    path: '/manage/courses',
    name: 'courses',
    props: false,
    meta: {
      auth: true,
    },
    children: [
      {
        path: '',
        name: 'products',
        component: () => import('../views/Courses/ManageCourses.vue'),
        meta: {
            title: 'Courses',
            type: 'courses',
        },
      },
      {
          path: 'create/:curriculum_id?',
          name: 'courses.create',
          component: () => import('../views/Courses/CreateCoursePage.vue'),
          meta: {
            title: 'Create Course'
          }
      },
      {
        path: 'edit/:id/',
        name: 'courses.edit',
        component: () => import('../views/Courses/EditCoursePage.vue'),
        meta: {
          title: 'Edit Courses'
        }
    },
    ]
  },
  {
    path: '/manage/curriculums',
    name: 'ManageCurriculums',
    component: () => import('../views/ManageCurriculums.vue'),
    meta: {
      title: 'Manage Curriculums'
    }
  },
  {
    path: '/:curriculum_id/manage/courses',
    name: 'ManageCurriculumCourses',
    props: true,
    component: () => import('../views/Courses/ManageCourses.vue'),
    meta: {
      title: 'Manage Courses'
    }
  },
  {
    path: '/groups',
    props: false,
    meta: {
      auth: true,
    },
    children: [
      {
        path: '',
        name: 'groups',
        component: () => import('../views/groups/GroupsPage.vue'),
        meta: {
            title: 'Groups',
        },
      },
      {
          path: ':id',
          name: 'group',
          component: () => import('../views/groups/GroupPage.vue'),
          meta: {
            title: 'Group Details'
          }
      },
    ]
  },
]

export const router = createRouter({
  history: createWebHistory(),
  base: ['staging', 'production'].includes(process.env.NODE_ENV)
      ? '/'
      : process.env.BASE_URL,
  linkActiveClass: 'active',
  routes: routes
});

router.beforeEach(async (to,from, next) => {
  /**
   * Central Redirections
   */
  if (to.name === 'My Profile') {
    window.open('/central/profile');
  }

  if (to.name === 'Preference') {
    window.open('/central/preference');
  }

  if (to.name === 'Manage Users') {
    window.open('/central/manage-users');
  }
  
  if (to.name === 'Mystery Shops') {
    window.open('/redirect/shops'); 
  }
  if (to.name === 'File Manager') {
    window.open('/central/file-manager');
  }

  
   /* Restricted Pages */
  const roles = getRoles();
  
  if ( !roles.isSuperAdmin.value && 
    (
      to.name === 'ManageCurriculumCourses' || 
      to.name === 'ManageCurriculums'
    ) 
   ) {
    router.push({
      name: "PageNotFound",
    });
  }

  
  if ( !(roles.isSuperAdmin.value || roles.isAccountManager.value) && 
    (
      to.name === 'ManageCourses' 
    ) 
   ) {
    router.push({
      name: "PageNotFound",
    });
  }

  if (!to.query.redirect && to.matched.length === 0) {
    // Redirect to PageNotFound if route not found and no redirect query parameter
    router.push({
      name: "PageNotFound",
    });
  }

  if (to.name == 'login' && to.redirectedFrom) {
    router.push({
      name: "login",
      query: {
        nextUrl: to.redirectedFrom.fullPath
      }
    });
  }

  next();
});

router.afterEach(() => {
  var favicon = 'https://ficexperts-lms.s3.amazonaws.com/default_favicon_fic.png';
  var link = document.querySelector("link[rel~='icon']");
  if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.href = favicon;
});