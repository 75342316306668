<template>
  <Menu as="div" class="ml-3 relative">
    <MenuButton
      class="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500"
    >
      <span class="sr-only">View Apps</span>
      <ViewGridIcon class="h-6 w-6 text-untitled-gray-400 hover:text-untitled-gray-500 focus:ring-untitled-gray-500" aria-hidden="true" />
    </MenuButton>
    <transition
      enter-active-class="transition ease-out duration-200"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="z-10 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <!-- Use the `active` state to conditionally style the active item. -->
        <MenuItem
          v-for="client in apps"
          :key="client.link"
          as="template"
          v-slot="{ active }"
          :class="[
            active ? 'bg-untitled-gray-100' : '',
            'block px-4 py-2 text-sm text-untitled-gray-700',
          ]"
        >
          <a
            :href="client.link"
            target="_blank"
            :class="[
              active ? 'bg-untitled-gray-100' : '',
              'block px-4 py-2 text-sm text-untitled-gray-700',
            ]"
          >
            <img
              class="w-5 inline-block"
              :src="client.cover_photo"
              alt="App Menu"
            />
            {{ client.name }}
          </a>
        </MenuItem>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup>
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue';
import { ViewGridIcon } from '@heroicons/vue/outline';
import { ref, onMounted, inject } from 'vue';

const apps = ref([
  {
    id: 3,
    name: 'Revdojo Central',
    link: 'https://central.webinarinc.com/',
    cover_photo:
      'https://d3tg77rzq6u33x.cloudfront.net/ba861b2e-950d-4a60-a213-5bbde2c1fc72/assets/revdojo_default.jpg',
    thumbnail:
      'https://d3tg77rzq6u33x.cloudfront.net/ba861b2e-950d-4a60-a213-5bbde2c1fc72/assets/revdojo_default.jpg',
    access: 1,
  },
]);

const axios = inject('axios');

onMounted(async () => {
  await axios.get('/api/central/get-app-menu').then(({ data }) => {
    apps.value = data;
  });
});


</script>
