<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class="relative transform rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div class="mt-3 text-center sm:text-left">
                <SkeletonGroupUsers :lineCount="1" v-if="loading" />
                <DialogTitle v-else as="h3" class="text-lg font-medium leading-6 text-gray-900" id="Group-Add-Members-Modal-label">{{ group.name }}
                </DialogTitle>
              </div>

              <SkeletonGroupUsers :lineCount="1" v-if="loading" />
              <div class="mt-6" v-else>
                <el-select-v2 class="w-full" v-model="selectedUserIds" :options="userDropdownOptions" :reserve-keyword="false" id="Group-Add-Members-Modal-Members-field"
                  placeholder="Add users as members to your group" multiple filterable clearable collapse-tags
                  collapse-tags-tooltip />
              </div>

              <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">

                <button type="button"
                  id="Group-Add-Members-Modal-Cancel-button"
                  class="inline-flex justify-center rounded-md border border-untitled-gray-300 bg-white px-4 py-2 text-base font-medium text-untitled-gray-700 shadow-sm hover:text-untitled-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                  @click="cancelButton()" :disabled="loading">Cancel</button>
                <VButton
                  id="Group-Add-Members-Save-button"
                  class="inline-flex items-center justify-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-untitled-gray-800 bg-untitled-gray-200 hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500 mr-2"
                  :loading="loading" :disabled="loading" @click.prevent="handleClickSave()">
                  Save
                </VButton>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
      
<script setup>
import { ref, defineEmits, onMounted, inject } from "vue";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { getUsers } from "@/composable/users/getUsers";
import { ElSelectV2 } from "element-plus";
import Bugsnag from '@bugsnag/js';
import EventBus from "@/plugins/eventBus";
import { VButton } from 'revdojo-vue-components';
import SkeletonGroupUsers from '@/components/loaders/SkeletonGroupUsers.vue';



const axios = inject('axios');
const userDropdownOptions = ref([]);
const selectedUserIds = ref([]);
const open = ref(false);
const loading = ref(false);

const group = ref({
  id: null,
  name: ''
});

const { userList, users } = getUsers();


const emit = defineEmits(['save']);

const cancelButton = () => {
  open.value = false;
}

const handleClickSave = async () => {
  loading.value = true;

  const formData = {
    id: group.value.id,
    name: group.value.name,
    users: selectedUserIds.value
  }

  await updateGroup(group.value.id, formData);
  emit('save', 'Group updated successfully');

  loading.value = false;
  open.value = false;
}

const updateGroup = async (id, formData) => {
  await axios.put(`/api/v3/groups/${id}`, formData)
    .then((response) => {
      EventBus.$emit('updateGroup', response.data);
    })
    .catch(error => {
      Bugsnag.notify(error);
    })
}


EventBus.$on('openGroupAddMembersModal', async (selectedGroup) => {

  selectedUserIds.value = [];
  group.value = {
    id: null,
    name: ''
  };

  open.value = true;
  loading.value = true;

  await axios.get(`/api/v3/groups/${selectedGroup.id}`)
    .then((response) => {
      group.value = response.data
      selectedUserIds.value = group.value.users;
    })
    .catch(error => {
      Bugsnag.notify(error);
    })
    .finally(() => {
      loading.value = false;
    });


})

onMounted(async () => {
  await userList();
  userDropdownOptions.value = users.value.map(({ id, name }) => ({
    label: name,
    value: id,
  }));
});


</script>
      