<template>
  <div class="w-full">
    <div class="py-10 w-full px-6">
      <h2 class="text-untitled-untitled-gray-900 text-xl mb-6">Your Playlists</h2>
      <top-section :loading="isLoading"></top-section>
      <div
        class="mx-auto mt-6 md:h-[600px] lg:h-[700px] overflow-y-scroll"
      >
        <ul v-if="learningPaths.length > 0" role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
          <li v-for="(learningPath, index) in learningPaths" :key="index" class="flex flex-col rounded-lg bg-white">
            <playlist-card
              :reload="getLearningPaths"
              :pathId="learningPath.learning_path_id"
              :title="learningPath.title"
              :thumbnail="learningPath.thumbnail"
              :totalVideosCompleted="learningPath.total_completed_videos_count"
              :totalVideoDuration="learningPath.total_learning_path_duration"
              :totalVideos="learningPath.total_videos_count"
              :dueDate="learningPath.due_date"
              :contents="learningPath.contents"
              :isOwner="learningPath.is_owner"
            ></playlist-card>
          </li>
        </ul>
        <div class="text-center mt-5">
          <button v-if="(!isLoading && !isLastPage && learningPaths.length > 0)" type="button" @click="viewMore()">View More Playlist</button>
        </div>
        <template v-if="isInit">

          <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
            <li v-for="i in 6" :key="i" class="flex flex-col rounded-lg bg-white">
              <SkeletonPlaylist />
            </li>
          </ul>
        </template>
        <div v-if="learningPaths.length <= 0 && !isLoading" class="text-center mt-5">
          <p class="text-gray-700">No Playlist Available.</p>
        </div>
      </div>
      <div class="text-center" v-if="isLoading && !isInit">
        <loading-state />
      </div>
    </div>
  </div>
</template>
<script>
import TopSection from "./TopSection.vue";
import { ref, inject, nextTick } from "vue";
import { forEach as _forEach } from "lodash-es";
import EventBus from "@/plugins/eventBus";
import LoadingState from '@/components/partials/LoadingState.vue';
import SkeletonPlaylist from "@/components/loaders/SkeletonPlaylist";
import PlaylistCard from "@/components/home/learningpath/PlaylistCard.vue";
import { useLearningPathStore } from '@/stores/learningPath';

export default {
  components: {
    PlaylistCard,
    TopSection,
    LoadingState,
    SkeletonPlaylist,
  },
  setup() {
    const axios = inject("axios");
    const filterBy = ref(null);
    const sortBy = ref("");
    const isLastPage = ref(false);
    const totalItems = ref(9);
    const isLoading = ref(false);
    const isInit = ref(true);
    const learningPathStore = useLearningPathStore();

    return {
      axios,
      isLastPage,
      filterBy,
      totalItems,
      isLoading,
      isInit,
      sortBy,
      learningPathStore,
    };
  },
  computed: {
    learningPaths() {
      return this.learningPathStore.learningPaths;
    }
  },
  created() {

    if (this.learningPaths.length==0) {
      this.getLearningPaths();
    }

    if (this.learningPaths.length>0) {
      this.isInit = false;
    }



    EventBus.$on("REFRESH_LEARNING_PATH", () => {
      this.isInit = true;
      this.learningPathStore.$patch({ learningPaths: [] });
      this.getLearningPaths();
    });

    EventBus.$on("OPEN_ASK_USER_TO_ASSIGN_LEARNING_PATH_DIALOG", () => {
      this.getLearningPaths();
    });

    EventBus.$on("UPDATE_LEARNING_PATH_DASHBOARD_SORT_BY", (sortValue) => {
      this.sortBy = sortValue;
      this.applyFilterAndSort();
    });

    EventBus.$on("UPDATE_LEARNING_PATH_DASHBOARD_FILTER_BY", (filterValue) => {
      this.filterBy = filterValue;
      this.applyFilterAndSort();
    });
  },
  mounted() {
    this.applyFilterAndSort();
  },
  methods: {
    toggleLoading() {
      this.isLoading = !this.isLoading;
    },
    applyFilterAndSort() {
            this.learningPathStore.$patch({ learningPaths: [] });
      this.isInit = true;
      this.getLearningPaths();
    },
    getLearningPaths: async function () {
      this.toggleLoading();
      await this.axios
        .get("/api/v3/dashboard/learning-paths", {
          params: {
            total_items: this.totalItems,
            sort_by: this.sortBy,
            filter_by: this.filterBy,
          },
        })
        .then(({ data }) => {
          if (this.totalItems !== 9) {
            this.learningPathStore.$patch({ learningPaths: [] });
          }

          _forEach(data.items, (learningPath) => {
            this.learningPathStore.addLearningPath(learningPath);
          });

          if(data.total_count_of_items <= this.totalItems) {
            this.isLastPage = true;
          }

          nextTick(() => {
            this.toggleLoading();
            this.isInit = false;
          });
        })
        .catch(() => {
          nextTick(() => {
            this.toggleLoading();
          });
        });
    },
    viewMore(){
        this.totalItems += 9;
        this.getLearningPaths();
    }
  },
};
</script>
