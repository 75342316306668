<script setup>
import { defineProps, computed } from 'vue';
const props = defineProps({
    display: {
        type: Number,
        default: 1
    },
    showAvatar: {
        type: Boolean,
        default: true
    }
})

const display = computed(() => props.display)
const showAvatar = computed(() => props.showAvatar)
</script>
<template>
    <div class=" w-full">
        <div class="border border-untitled-gray-300 shadow-md rounded-md p-4 w-full" :class="n == 1 ? '' : 'mt-4'"
            v-for="n in display" :key="n">
            <div class="animate-pulse flex space-x-4">
                <div class="rounded-full bg-untitled-gray-700 h-10 w-10" v-if="showAvatar"></div>
                <div class="flex-1 space-y-6 py-1">
                    <div>
                        <div class="h-2 w-96 bg-untitled-gray-700 rounded"></div>
                    </div>
                    <div>
                        <div class="h-2 w-4/5 bg-untitled-gray-700 rounded"></div>
                    </div>
                    <div class="space-y-3">
                        <div class="grid grid-cols-3 gap-4">
                            <div class="h-2 bg-untitled-gray-700 rounded col-span-2"></div>
                            <div class="h-2 bg-untitled-gray-700 rounded col-span-1"></div>
                        </div>
                        <div class="h-2 bg-untitled-gray-700 rounded"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>