import { eventEndpoints } from "@/endpoints/event"
import EventModel from "@/model/EventModel"
import { errorHandler } from "../error_handler";
import { ref } from "vue";

export const postEvent = async (event) => {
    const errorValue = ref(null)
    const {isError, error, loading, data} = await new EventModel(eventEndpoints.resource).post(event);
    if(isError && error) {
        const { data: errorResponse } = errorHandler(error);
        errorValue.value = errorResponse
    }

    return {
        data,
        errorValue,
        loading,
        isError
    }
}