<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-[85] w-full" @click="close">
      <TransitionChild
      as="template"
      enter="ease-out duration-300"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="ease-in duration-200"
      leave-from="opacity-100"
      leave-to="opacity-0"
      >
      <div
      class="fixed inset-0 bg-untitled-gray-500 bg-opacity-75 transition-opacity"
      />
    </TransitionChild>
    
    <div class="fixed inset-0 z-[85] overflow-y-auto">
      <div
      class="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0"
      >
      <TransitionChild
      as="template"
      enter="ease-out duration-300"
      enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      enter-to="opacity-100 translate-y-0 sm:scale-100"
      leave="ease-in duration-200"
      leave-from="opacity-100 translate-y-0 sm:scale-100"
      leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
      <DialogPanel
      class="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all lg:my-8 lg:max-w-2xl md:my-8 md:max-w-md sm:my-8 sm:max-w-sm sm:w-full sm:p-6"
      >
      <div class="absolute top-0 right-0 pt-4 pr-4 pb-10 sm:block">
        <button type="button" class="text-untitled-gray-400" @click="close">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
        </button>
      </div>
      <div class="flex mt-3 flex-col md:flex-row  lg:flex-row justify-between">
        <DialogTitle
            as="h3"
            class="text-lg align-middle font-medium leading-6 text-untitled-gray-900"
          >
            Completed Courses
          </DialogTitle>
   
          <div class="text-base w-auto lg:w-[200px] md:w-[190px]" >
              <v-select class="w-full" v-model="sortBy" :options="sorts" option-name="name" option-value="value" name="filter" placeholder="Sort By"></v-select>
          </div>
   
  
      </div>
      <LoadingState v-if="loading" class="mx-auto text-center"/>
      <div class="" v-else>
        
        <ul v-if="filteredCourseList.length > 0"  
          :style="filteredCourseList.length < 4 ? 'height:auto' : 'height: 600px;'"
          class="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 overflow-auto">
          <li
          class="grid py-6 px-4  shadow-md m-2"
          v-for="course in filteredCourseList"
          :key="course.id"
          >
          <div class="flex-shrink-0" v-if="course" >
            
            <!-- <div v-if="course.unit?.video_url.includes('iframe') || course.unit?.video_url.includes('youtube')" >
              <div class="" v-html="course.unit?.video_url" />
            </div> -->
            <div class="cursor-pointer" @click="openCourse(course.unit.id)">
              <img
              v-if="course.thumbnail"
              :src="course.thumbnail"
              class="w-full h-auto"
              />
              <img
              v-else
              src="https://lms-v2-staging.s3-us-west-2.amazonaws.com/modules/default-thumbnail.jpg"
              class="w-full h-auto"/> 
            </div> 
          </div>
          
          <div
          class="mt-2 flex flex-1 flex-col "
          >
          <div>
            <div class="flex justify-between">
              <h4 class="text-sm">
                <a
                href="#"
                class="font-medium text-untitled-gray-700 hover:text-untitled-gray-800"
                >{{ course.name }}</a
                >
              </h4>
              <p class="ml-4 text-sm font-medium text-untitled-gray-900">
                {{ course.completed_unit_count }}/{{ course.unit_count }} Units
              </p>
            </div>
          </div>
          
          <div class="grid">
            
            
            <div class="w-full mx-auto ">
              
              <div class="flex justify-between pt-5" v-if="course.is_certified">
                
                <p class="text-xs text-untitled-gray-500 font-bold">
                  <v-button
                  v-if="course.is_certified"
                  @click="downloadCertificate(course.id, 'course')"
                  type="button"
                  class="font-medium text-xs">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 mr-1">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                  </svg>
                  
                  Certificate
                  </v-button>
                
              </p>
              <p class="text-xs text-right" v-if="course.is_certified">
                Certifcation Date: <br/>
                {{ formatDate(course.certified_at,"MM/DD/YYYY") }}
              </p>
            </div>
            
            <div class="flex justify-between pt-5" v-else>
              
              <p class="text-xs text-untitled-gray-500 font-bold">
                <button
                @click="openCourse(course.unit?.id)"
                type="button"
                class="mr-4 inline-flex items-center px-3 py-2 border border-transparent text-xs leading-4 font-medium rounded-md shadow-sm text-untitled-gray-600 bg-untitled-gray-200 hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500"
                >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 mr-1">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                </svg>
                Open Course
              </button>
              
              
            </p>
            <!-- <p class="text-xs text-right">Certifcation Date: <br/>{{ course.created_at}}</p> -->
          </div>
          
          
        </div>
        
      </div>
    </div>
  </li>
        </ul>
        <div v-else class="text-center py-6">
          <p class="text-untitled-gray-500">No course found</p>
        </div>
    </div>
</DialogPanel>
</TransitionChild>
</div>
</div>
</Dialog>
</TransitionRoot>
</template>

<script setup>
  import { ref, inject, onMounted, computed, defineEmits, watch } from "vue";
  import {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  } from "@headlessui/vue";
  import { useRouter } from "vue-router";
  import LoadingState from "@/components/partials/LoadingState.vue";
  import { useAuthStore } from '@/stores';
  import {VSelect, VButton} from 'revdojo-vue-components'
  import Bugsnag from "@bugsnag/js";
  import formatDate from "@/composable/formatDate";
  
  const open = ref(true);
  const courseList = ref([]);
  const emit = defineEmits(["close"]);
  const axios = inject("axios");
  const router = useRouter();
  const auth = useAuthStore();
  const loading = ref(false)

onMounted(() => {
  fetchCompletedCourses();
});

const sortBy = ref('');
const sorts = ref([
    {
        name: 'A-Z',
        value: 'a-z'
    },
    {
        name: 'Z-A',
        value: 'z-a'
    },
    {
        name: 'Highest to Lowest',
        value: 'htl'
    },
    {
        name: 'Lowest to Highest',
        value: 'lth'
    }
]);

watch(sortBy, (val) => {
    changeSort(val);
});

function changeSort(sortBy) {
    courseList.value.sort((a, b) => {
        switch (sortBy) {
            case 'a-z':
                return a.name.localeCompare(b.name);
            case 'z-a':
                return b.name.localeCompare(a.name);
            case 'htl':
                return b.completed_unit_count - a.completed_unit_count;
            case 'lth':
                return a.completed_unit_count - b.completed_unit_count;
        }
    });
}

  const filteredCourseList = computed(() => {
    return courseList.value;
  });
  
  const close = () => {
    emit("close", open.value);
  };

  const sendCertificate = async (id,type) => {
    await axios.post(`/api/download/certificate`,
    {
      userId: auth.user?.id,
      id: id,
      type: type
    } 
    ).then((response) => {
      window.open(response.data, '_blank');
    }).catch((error) => {
      Bugsnag.notify(error);
    });
  };
  
  function downloadCertificate(id, type) {
    sendCertificate(id, type);
  }

  function openCourse(id) {
    router.push(`/units/${id}`);
  }

  const fetchCompletedCourses = async () => {
    loading.value = true;
    await axios
        .get("/api/v3/user/my-progress/courses-completed")
        .then((response) => {
            courseList.value = response.data;
            loading.value = false;
        })
        .catch((error) => {
           Bugsnag.notify(error);
           loading.value = false;
        });
}

  
  
</script>
<style>
  iframe {
    width: 100%;
    height: inherit;
  }
</style>