import { useAuthStore } from "@/stores";
import { computed } from "vue";

export const getRoles = () => {
    const authUser = useAuthStore();
    const user = authUser.$state.user

    const isSuperAdmin = computed(() => {
        const isExists = user?.roles?.find(
          (role) => role.name === "super-administrator"
        );
      
        return isExists !== undefined;
    });
      
    const isAccountManager = computed(() => {
        const isExists = user?.roles?.find(
          (role) => role.name === "account-manager"
        );
      
        return isExists !== undefined;
    });

    const isSpecificManager = computed(() => {
      const isExists = user?.roles?.find(
        (role) => role.name === "specific-dealer-manager"
      );
    
      return isExists !== undefined;
  });

    const isUser = computed(() => {
        const isExists = user?.roles?.find(
          (role) => role.name === "learner" || role.name === "salesperson"
        );
      
        return isExists !== undefined;
    });



    return {isSuperAdmin, isAccountManager, isSpecificManager, isUser}
}
