<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon, PlusIcon, EyeIcon } from "@heroicons/vue/solid";

import CreateEventDialog from "./CreateEventDialog";
import eventBus from "@/plugins/eventBus";
import dayjs from "dayjs";
import { onMounted, ref, computed, watch } from "vue";
import { fetchUpcomingEvents } from "@/composable/event/get-upcoming-events";
import GlobalListSkeleton from "@/components/layout/GlobalListSkeleton.vue";

const isOpen = ref(false);
const params = computed(() => {
  return { 
    userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    isFromHomePage: true
  }
})
const { load: loadEvents, data: events, loading} = fetchUpcomingEvents(params.value)

const openRegisterLink = (event) => {
  window.open(event.url, "_blank");
};
const openModal = () => {
  isOpen.value = true;
};

const formatDate = (start_at) => {
  return dayjs(start_at).format('ddd, MMM D, YYYY h:mm A')
}

onMounted( async () => {
  await loadEvents(params.value)
  eventBus.$on("NEW_EVENT", () => {
    loadEvents(params.value)
  });
});

watch(params, async () => {
  console.log('asd')
  await loadEvents(params.value)
})
</script>
<template>
  <div>
    <div class="-ml-4 -mt-2 flex justify-between flex-wrap sm:flex-nowrap">
      <div class="ml-4 mt-4">
        <h3 class="text-untitled-gray-800 font-bold inline">Upcoming Events</h3>
      </div>
      <div class="ml-4 mt-2 flex-shrink-0 w-full xl:w-auto lg:w-auto md:w-auto flex justify-end">
        <Menu as="div" class="relative inline-block text-left z-10">
          <div>
            <MenuButton
              class="inline-flex justify-center w-full rounded-md border border-untitled-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-untitled-gray-700 hover:bg-untitled-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-untitled-gray-100 focus:ring-untitled-gray-500"
            >
              Options
              <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
            </MenuButton>
          </div>

          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-untitled-gray-100 focus:outline-none"
            >
              <div class="py-1">
                <MenuItem
                  v-slot="{ active }"
                >
                  <a
                    href="#"
                    :class="[
                      active
                        ? 'bg-untitled-gray-100 text-untitled-gray-900'
                        : 'text-untitled-gray-700',
                      'group flex items-center px-4 py-2 text-sm',
                    ]"
                    @click="openModal"
                  >
                    <PlusIcon
                      class="mr-3 h-5 w-5 text-untitled-gray-400 group-hover:text-untitled-gray-500"
                      aria-hidden="true"
                    />
                    Create Event
                  </a>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <router-link
                    to="/events"
                    :class="[
                      active
                        ? 'bg-untitled-gray-100 text-untitled-gray-900'
                        : 'text-untitled-gray-700',
                      'group flex items-center px-4 py-2 text-sm',
                    ]"
                  >
                    <EyeIcon
                      class="mr-3 h-5 w-5 text-untitled-gray-400 group-hover:text-untitled-gray-500"
                      aria-hidden="true"
                    />
                    View All Events
                  </router-link>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>
    </div>
    <div class="flex items-center justify-center">
      <div class="md:py-4 py-5 w-full">
        <div class="px-4">
          <template v-if="!events.length && !loading">
            <div class="flex justify-center items-center content-center h-56">
              <h3>No upcoming events</h3>
            </div>
          </template>
          <template v-if="events && events.length && !loading">
            <div
              v-for="event in events"
              :key="event.id"
              class="border-b pb-4 border-untitled-gray-400 border-dashed"
            >
              <p
                class="text-xs font-light leading-3 text-untitled-gray-500 mt-5"
              >
                {{formatDate(event.start_at_formatted)  }}
              </p>
              <a
                tabindex="0"
                class="focus:outline-none text-lg font-medium leading-5 text-untitled-gray-800 mt-2"
                >{{ event.name }}</a
              >
              <p
                class="text-sm pt-2 leading-4 leading-none text-untitled-gray-600"
              >
                {{ event.description }}
              </p>
              <button
                type="button"
                v-if="event.is_done"
                class="mt-5 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-untitled-gray-800 bg-untitled-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-800 cursor-not-allowed"
              >
                This event has been completed
              </button>
              <button
                v-else
                @click="openRegisterLink(event)"
                class="mt-5 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-untitled-gray-800 bg-untitled-gray-200 hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-800"
              >
                Register now
              </button>
            </div>
          </template>
          <div v-if="loading">
            <GlobalListSkeleton :display="3" :show-avatar="false"/>
          </div>
        </div>
      </div>
    </div>
  </div>

  <create-event-dialog v-model="isOpen"/>
</template>