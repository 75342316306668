import Model from "./Model";

class ReminderModel extends Model {
    constructor(url = null) {
        super(url);
    }

    // custom methods
}


 
export default ReminderModel;