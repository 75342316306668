<template>
  <div class="py-10 w-full px-6">
    <h2 class="text-untitled-gray-900 text-xl mb-6">All Courses</h2>
    <div class="flex flex-col lg:flex-row w-full lg:items-center xl:w-2/3">
      <div class="w-full lg:w-1/2  mb-4 lg:mb-0 lg:mr-3 md:flex-1">
        <input
        v-model="search"
        type="text"
        placeholder="Search"
        @keyup.enter="searchCourse"
        class="cursor-pointer focus:outline-none focus:border-gray-800 focus:shadow-outline-gray text-sm form-select block w-full py-2 px-2 xl:px-3 border border-gray-300 rounded text-gray-600 appearance-none bg-transparent"
        />
      </div>
      <div class="w-full mb-4 lg:mb-0 lg:mr-3  md:flex-1">
        <ECombobox
          v-model="sortBy" 
          name="sorts" 
          :options="sorts" 
          option-name="name" 
          value-key="value" 
          placeholder="Sort By" 
          :multiple="false" 
          :filterable="false" 
          :clearable="false"/>
      </div>
      <div class="w-full mb-4 lg:mb-0 lg:mr-3 md:flex-1 ">
        <ECombobox
          v-model="filteredBy" 
          name="filters" 
          :options="filters" 
          option-name="name" 
          value-key="value" 
          placeholder="Filter By" 
          :multiple="false" 
          :filterable="false" 
          :clearable="false"/>
      </div>
      <button
        @click="resetFunctions()"
        class="relative bg-untitled-gray-100 hover:bg-untitled-gray-200 cursor-pointer focus:outline-none focus:border-untitled-gray-200 focus:shadow-outline-gray text-sm form-select block w-1/8 py-2 px-2 xl:px-3 border border-untitled-gray-100 rounded appearance-none text-untitled-gray-800 hover:text-untitled-gray-900"
      >
        Reset
      </button>
    </div>
  </div>
  <div class="text-center" v-if="isLoading">
    <SkeletonCourseCard
      v-for="i in 4"
      :key="i"
      class="mb-2"
      />
  </div>
  <div v-else class="overflow-y-scroll md:h-[800px] lg:h-[1550px]">
    <template v-if="courses.length > 0">
      <div v-for="(course, index) in courses" :key="index">
        <SingleCourse class="mt-3 mb-3" :course="course" />
      </div>
    </template>
    <div class="text-center" v-else>
      <div class="block">No courses found.</div>
    </div>
  </div>
</template>
<script setup>
import SingleCourse from "@/components/home/courses/SingleCourse";
import SkeletonCourseCard from "@/components/loaders/SkeletonCourseCard";
import { useRouter } from 'vue-router';
import Bugsnag from "@bugsnag/js";
import eventBus from '@/plugins/eventBus';
import ECombobox from "../element-components/ECombobox.vue";
import { ref, watch, inject, onMounted } from "vue";

const router = useRouter();
const axios = inject('axios')

const courses = ref([])
const allCourses = ref([])
const sortBy = ref(null);
const filteredBy = ref(null);
const isLoading = ref(false)
const search = ref('');
const filters = ref([
    {
      name: 'All',
      value: 'all'
    },
    {
      name: 'Not Started',
      value: 'not started'
    },
    {
      name: 'In Progress',
      value: 'in progress'
    },
    {
      name: 'Completed',
      value: 'completed'
    },
])

const sorts = ref([
      {
        name: 'A-Z',
        value: 'a-z'
      },
      {
        name: 'Z-A',
        value: 'z-a'
      },
      {
        name: 'High to Low Progress',
        value: 'htl'
      },
      {
        name: 'Low to High Progress',
        value: 'lth'
      },
  ])

  const fetchCourses = async () => {
      isLoading.value = true;
      await axios
        .get("api/v3/courses/list",{
          params: {
            keyword: search.value
          }
        })
        .then((response) => {
          courses.value = response.data;
          allCourses.value = response.data;
          isLoading.value = false;
        })
        .catch((error) => {
          Bugsnag.notify(error);
        });
    }

  const changeFilter = () => {
    switch (filteredBy.value) {
      case "not started":
        courses.value = allCourses.value.filter((course) => {
          return course.completed_percentage == 0;
        });
        break;
      case "in progress":
        courses.value = allCourses.value.filter((course) => {
          return course.completed_percentage > 0 && course.completed_percentage < 100;
        });
        break;
      case "completed":
        courses.value = allCourses.value.filter((course) => {
          return course.completed_percentage == 100;
        });
        break;
      case "all":
        courses.value = allCourses.value;
        break;
    }
  }

  const changeSort = () => {
    switch (sortBy.value) {
      case "a-z":
        courses.value = courses.value.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
        break;
      case "z-a":
        courses.value = courses.value.sort((a, b) =>
          a.name < b.name ? 1 : b.name < a.name ? -1 : 0
        );
        break;
      case "lth":
        courses.value = courses.value.sort((a, b) =>
          a.completed_percentage > b.completed_percentage
            ? 1
            : b.completed_percentage > a.completed_percentage
            ? -1
            : 0
        );
        break;
      case "htl":
        courses.value = courses.value.sort((a, b) =>
          a.completed_percentage < b.completed_percentage
            ? 1
            : b.completed_percentage < a.completed_percentage
            ? -1
            : 0
        );
        break;
      case "default":
        courses.value = courses.value.sort((a, b) =>
          a.id > b.id ? 1 : b.id > a.id ? -1 : 0
        );
        break;
    }
  }
  
  const resetFunctions = () => {
      sortBy.value = null;
      filteredBy.value =  null ;
      courses.value = allCourses.value;
      changeSort();
      fetchCourses();
  }

  const searchCourse = () => {
      isLoading.value = true;
      router.push({
        name: 'search',
        query:{
          search: search.value
        }
      })
    }

  onMounted(() => {
    fetchCourses();

    eventBus.$on('reset-course-list', () => {
      fetchCourses();
    });
  })

  watch(filteredBy, () => {
    changeFilter();
  })

  watch(sortBy, () => {
    changeSort()
  })
</script>
