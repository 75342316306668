import axios from "axios";
import { useAuthStore } from "@/stores";
import { s3 } from "./s3";
import Bugsnag from "@bugsnag/js";
export const singleUpload = (file, folder, expiry = 10) => {
  const key = folder + "/" + Date.now() + "-" + file.name.replace(/\s/g, "-");

  const params = {
    Bucket: "lms-v3-staging",
    Key: key,
    Expires: expiry,
    ContentType: file.type,
  };
  const url = s3.getSignedUrl("putObject", params);

  return axios
    .put(url, file, {
      headers: {
        "Content-Type": file.type,
        "X-Requested-With": "XMLHttpRequest",
      },
    })
    .then((result) => {
      const bucketUrl = decodeURIComponent(result.request.responseURL).split(
        key
      )[0];
      result.key = key;
      result.fullPath = bucketUrl + key;
      return result;
    })
    .catch((err) => {
      Bugsnag.notify(err);

      return {
        status: 403,
        error: err.message,
      };
    });
};

export const store = async (file, options = {}) => {
  const authStore = useAuthStore();

  const http = axios.create({
    baseURL: "/api",
    headers: {
      Authorization: `Bearer ${authStore.$state.token}`,
    },
  });

  var instance = axios.create();
  delete instance.defaults.headers.common["Authorization"];

  const response = await http.post(
    options.signedStorageUrl ? options.signedStorageUrl : '/vapor/signed-storage-url',
    {
      bucket: options.bucket || "",
      content_type: options.contentType || file.type,
      expires: options.expires || "",
      visibility: options.visibility || "",
      ...options.data,
    },
    {
      baseURL: options.baseURL || null,
      headers: options.headers || {},
      ...options.options,
    }
  );

  let headers = response.data.headers;

  if ("Host" in headers) {
    delete headers.Host;
    delete headers.Authorization;
  }

  if (typeof options.progress === "undefined") {
    options.progress = () => {};
  }

  const cancelToken = options.cancelToken || "";

  await instance.put(response.data.url, file, {
    cancelToken: cancelToken,
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": file?.type,
    },
    onUploadProgress: (progressEvent) => {
      options.progress(progressEvent.loaded / progressEvent.total);
    },
  });

  response.data.extension = file.name.split(".").pop();

  return response.data;
};
