import { errorHandler } from "../error_handler";
import { ref } from "vue";
import EventModel from "@/model/EventModel";
import { eventEndpoints } from "@/endpoints/event";
import dayjs from "dayjs";

const data = ref([]);
const loading = ref(true);
const lastPage = ref(1);

export const fetchUpcomingEvents = () => {
    const load = async (params) => {
        loading.value = true
        const {
            isError,
            error,
            data: events,
        } = await new EventModel(eventEndpoints.getUpcomingEvents).get(params);

        loading.value = false;
        if(isError && error) {
            errorHandler(error);
        }
        const eventsData = events.map((item) => formatEventResponse(item));

        data.value = eventsData
    };

    return {
        load,
        data,
        lastPage,
        loading,
    };
};

const formatEventResponse = (event) => {
    return {
        ...event,
          fullDate: dayjs(event.start_at).format('MM/DD/YYYY hh:mm A'),
      };
}
