<template>
  <el-select-v2
    class="w-full"
    v-model="value"
    :options="customOptions"
    :placeholder="placeholder"
    :clearable="clearable"
    :filterable="filterable"
    :multiple="multiple"
    collapse-tags
    collapse-tags-tooltip
    :disabled="disabled"
    :loading="loading"
    v-bind="attrs"
    :name="name"
    :allow-create="allowCreate"
    ref="selectInput"
    :reserve-keyword="reserveKeyword"
    placement="bottom"
    @clear="clear"
    @remove-tag="handleRemoveValue"
  />

  <span class="text-red-500 text-xs italic mt-1">{{ errorMessage }}</span>
</template>

<script setup>
import { defineProps, defineEmits,computed, useAttrs } from "vue";
import { ElSelectV2 } from "element-plus";
import { useField } from "vee-validate";

const props = defineProps({
  modelValue: {
    default: null,
  },
  options: {
    type: Object,
  },
  placeholder: {
    default: "Placeholder",
    type: String,
  },
  valueKey: {
    type: String,
    default: null,
    required: true,
  },
  optionName: {
    type: String,
    default: null,
    required: true
  },
  disabled: {
    default: false,
    type: Boolean,
  },
  name: {
    default: "",
    type: String,
  },
  rules: {
    default: [],
  },
  multiple: {
    default: false,
    type: Boolean,
  },
  filterable: {
    default: false,
    type: Boolean,
  },
  clearable: {
    default: false,
    type: Boolean,
  },
  loading: {
    default: false,
    type: Boolean
  },
  allowCreate: {
    type: Boolean,
    default: false
  },
  reserveKeyword: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["update:modelValue","clear", "removeTag"]);

const attrs = useAttrs()


const clear = () => {
  emit("clear");
}
const validateField = (value) => {
  let error = true;

  for (let i = 0; i < props.rules?.length; i++) {
    const rule = props.rules[i];

    
    error = rule(value);

    if (error != true) {
      break;
    }
  }

  emit("update:modelValue", value);

  return error;
};


const customOptions = computed(() => {
    if(!props?.options?.length){
      return []
    }

    if(typeof props?.options[0] !== 'object'){
      return props?.options?.map((option) => {
          return {
              label: option,
              value: option
          }
      })
    }
    
    return props?.options?.map((option) => {
        return {
            label: option[props?.optionName],
            value: option[props?.valueKey]
        }
    })
})

const { errorMessage, value } = useField(props.name, validateField, {
  validateOnValueUpdate: true,
  initialValue: props?.modelValue,
});

const handleRemoveValue = (removableValue) => {
  if(props.multiple) {
    emit('removeTag', removableValue)
  }
}

</script>
