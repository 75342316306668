import { defineStore } from 'pinia';
import { useRouter } from "vue-router";
import axios from "axios";
export const useAuthStore = defineStore({
    persist: true,
    id: 'auth',
    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        user: null,
        returnUrl: null,
        token: null,
    }),
    actions: {
        async login(clientid) {
            // auth Route
            axios
                .post('/api/sso-post-login', {
                  clientid,
                })
                .then(({ data }) => {
                    // update pinia state
                    this.user = data.user;
                    axios.defaults.headers.common.Authorization = `Bearer ${data.token}`
                    // store user details and jwt in local storage to keep user logged in between page refreshes
                    localStorage.setItem('user', JSON.stringify(data.user));
                    localStorage.setItem('bearer_token', JSON.stringify(data.token))

                    // redirect to previous url or default to home page
                    useRouter().push('/');
                })
                .catch(() => {
                  //
                });
        },
        logout() {
            this.user = null;
            localStorage.removeItem('user');
            localStorage.removeItem('bearer_token');
            useRouter().push('/login');
        }
    }
});