import { http } from "@/composable/http_service";
export default class Model {
    constructor(url) {
        this._url = url;
        this._data = null;
        this._loading = false;
        this._isError = false;
        this._error = null;
    }

    async get(payload, customURL = null) {
        this._loading = true;
        await http().get(customURL ? customURL : this._url, { params: payload })
            .then(response => {
                this._data = response.data;
            }).catch(err => {
                this._isError = true;
                this._error = err;
                this._loading = false
            }).finally(() => {
                this._loading = false;
            })

        return {
            loading: this._loading,
            data: this._data,
            isError: this._isError,
            error: this._error
        }
    }

    // resource api
    async post(payload, customURL = null) {
        this._loading = true;
        await http().post(customURL ? customURL : this._url, payload)
            .then(response => {
                this._data = response.data;
            }).catch(err => {
                this._isError = true;
                this._error = err;
            }).finally(() => {
                this._loading = false;
            })

        return {
            loading: this._loading,
            data: this._data,
            isError: this._isError,
            error: this._error
        }
    }

    // resource put api
    async put(payload, customURL = null) {
        this._loading = true;
        await http().put(customURL ? customURL : this._url, payload)
            .then(response => {
                this._data = response.data;
            }).catch(err => {
                this._isError = true;
                this._error = err;
            }).finally(() => {
                this._loading = false;
            })

        return {
            loading: this._loading,
            data: this._data,
            isError: this._isError,
            error: this._error
        }
    }

    // resource p api
    async update(payload, customURL = null) {
        this._loading = true;
        await http().post(customURL ? customURL : this._url, payload)
            .then(response => {
                this._data = response.data;
            }).catch(err => {
                this._isError = true;
                this._error = err;
            }).finally(() => {
                this._loading = false;
            })

        return {
            loading: this._loading,
            data: this._data,
            isError: this._isError,
            error: this._error
        }
    }

    // resource api
    async destroy(customURL = null) {
        this._loading = true;
        await http().delete(customURL ? customURL : this._url)
            .then(response => {
                this._data = response.data;
            }).catch(err => {
                this._isError = true;
                this._error = err;
            }).finally(() => {
                this._loading = false;
            })

        return {
            loading: this._loading,
            data: this._data,
            isError: this._isError,
            error: this._error
        }
    }

}