import { reminderEndpoints } from "@/endpoints/reminder"
import ReminderModel from "@/model/ReminderModel"
import { errorHandler } from "../error_handler"
import { ref } from "vue"

export const postReminder = async (payload) => {
    const errorValue = ref(null)
    const { isError, error, data, loading } = await new ReminderModel(reminderEndpoints.resource).post(payload)
    if(isError && error) {
        const { data: errorData }  = errorHandler(error);
        errorValue.value = errorData
    }

    return {
        data,
        isError,
        errorValue,
        loading
    }
}