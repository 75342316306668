<template>
  <v-form @submit="submit()" class="mt-5 flex flex-col gap-2 overflow-y h-auto">
    <div class="flex flex-col">
      <label class="block text-sm font-medium text-untitled-gray-700">Assign to users</label>
      <e-combobox v-model="selectedUsers" name="users" :options="users" :loading="fetchingLoading" option-name="name"
        value-key="id" placeholder="Start typing to search users...." :multiple="true" :filterable="true"
        :clearable="true" :rules="selectedGroups?.length || requiredUserRules" />
    </div>
    <div class="flex flex-col mt-2">
      <div class="flex justify-between items-center mb-1" >
        <label class="block text-sm font-medium text-untitled-gray-700">Assign to groups</label>
        <a class="cursor-pointer px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm true text-center h-[auto] w-[auto] justify-center inline-flex items-center text-untitled-gray-800 bg-untitled-gray-200 hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500"
          @click="handleClickCreateGroup">
          Create Group
        </a>
      </div>
      <e-combobox v-model="selectedGroups" name="groups" :options="groups" :loading="fetchingLoading" option-name="name"
        value-key="id" :multiple="true" :filterable="true" :clearable="true"
        placeholder="Start typing to search groups...." :rules="selectedUsers?.length || requiredGroupRules" />
    </div>

    <div class="flex flex-col mt-2">
      <label class="block text-sm font-medium text-untitled-gray-700">Due Date</label>
      <div class="w-full">
        <v-date-picker :min-date="new Date()" class="text-sm" v-model="dueDate" enableTimePicker name="date"
          :rules="dateRules" />
      </div>
    </div>

    <div class="flex mt-5 justify-end ">
      <v-button type="submit"
        class="justify-center inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-untitled-gray-800 bg-untitled-gray-200 hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500 "
        :loading="loading">Save</v-button>
      <v-button type="button"
        class="ml-2 inline-flex  justify-center rounded-md border border-untitled-gray-300 bg-white px-4 py-2 text-base font-medium text-untitled-gray-700 shadow-sm hover:text-untitled-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
        @click="close()" ref="cancelButtonRef" :loading="loading">Cancel</v-button>
    </div>
  </v-form>


</template>
  
<script setup>
import { ref, computed, onMounted, defineEmits, defineProps, inject, watch } from "vue";
import { VDatePicker, VForm, VButton } from 'revdojo-vue-components'
import { getUsers, getGroups } from "@/composable/users/getUsers";
import dayjs from "dayjs";
import Bugsnag from "@bugsnag/js";
import EventBus from '@/plugins/eventBus';
import ECombobox from "@/components/element-components/ECombobox"


const axios = inject("axios");

const props = defineProps({
  close: {
    type: Function,
    default: () => { }
  },
  handleSubmit: {
    type: Function
  },
  unit: {
    type: Object,
  }
})

const selectedUsers = ref([]);
const selectedGroups = ref([]);
const dueDate = ref(null);

const requiredUserRules = [(v) => v?.length || "Select at least one User."];
const requiredGroupRules = [(v) => v?.length || "Select at least one Group."];
const dateRules = [
  (v) => (v) || "Date and Time is required",
];

const emit = defineEmits(["close", "assign", 'createGroup']);

const { userList, users, } = getUsers();
const { groupList, groups } = getGroups();

const loading = ref(false)
const fetchingLoading = ref(false)


onMounted(async () => {
  fetchingLoading.value = true
  await userList();
  await groupList();
  fetchingLoading.value = false
});


const assignedForm = computed(() => {
  let due_date = dayjs(dueDate.value).format("YYYY-MM-DD HH:mm:ss");

  return {
    assigned_users: selectedUsers.value || [],
    assigned_groups: selectedGroups.value || [],
    due_date: due_date
  }
});

const fetchAssigned = () => {
  emit("assign", assignedForm.value);
};


const submit = async () => {
  fetchAssigned()

  loading.value = true

  props?.unit ? await handleSubmit() : await props?.handleSubmit()

}


async function handleSubmit() {
  let userIds = selectedUsers.value

  let groupIds = selectedGroups.value

  let due_date = dayjs(dueDate.value).format(
    "YYYY-MM-DD HH:mm:ss"
  );


  await axios
    .post("/api/units/assign", {
      unit_id: props.unit.id,
      user_ids: userIds,
      group_ids: groupIds,
      notifyBy: [],
      due_date: due_date,
    })
    .then(() => {
      EventBus.$emit("NOTIFICATION_DIALOG", {
        show: true,
        message: "Assigned Successfully",
      });
      loading.value = false
    })
    .catch((error) => {
      Bugsnag.notify(error);
    });
    EventBus.$emit('REFRESH_NOTIFICATION')
}

const handleClickCreateGroup = () => {
  emit('createGroup');
}

EventBus.$on('addGroup', async (group) => {
  if (!selectedGroups.value) {
    selectedGroups.value = [];
  }
  selectedGroups.value.push(group.id)
  groups.value.push(group)
})


watch(selectedUsers, () => {
  if (!selectedGroups?.value?.length) {
    selectedGroups.value = null
  }
})

watch(selectedGroups, () => {
  if (!selectedUsers?.value?.length) {
    selectedUsers.value = null
  }
})


</script>
  
