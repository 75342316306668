/* eslint-disable */
<template>
  <div class="ml-6 mt-6" v-if="availableCurriculums.length > 1">
    <h2 class="text-untitled-gray-900 text-xl mb-4">Curriculum</h2>
    <div class="grid grid-cols-4" x-data="app">
      <template v-if="availableCurriculums.length > 2">
        <v-select
          :options="availableCurriculums"
          option-name="name"
          option-value="id"
          v-model="preferredCurriculum"
          @click="syncPreferredCurriculum()"
        >
        </v-select>
      </template>
      <template v-if="availableCurriculums.length <= 2">
        <template
          v-for="availableCurriculum in availableCurriculums"
          :key="availableCurriculum.id"
        >
          <div
          :class="{
              'z-10': preferredCurriculum === availableCurriculum.id,
              'z-1': preferredCurriculum !== availableCurriculum.id,
            }"
          >
            <input
              type="radio"
              name="option"
              v-model="preferredCurriculum"
              :id="availableCurriculum.id"
              :value="availableCurriculum.id"
              :checked="preferredCurriculum === availableCurriculum.id"
              class="peer hidden"
              @click="syncPreferredCurriculum()"
              />
            <label
              :for="availableCurriculum.id"
              class="block cursor-pointer py-1 -mr-5 select-none rounded-2xl text-center peer-checked:bg-gray-600 peer-checked:text-white border border-gray-200 bg-gray-200"
              >{{ availableCurriculum.name }}</label
            >
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, inject, computed, watch } from "vue";
import eventBus from "@/plugins/eventBus";
import { VSelect } from "revdojo-vue-components";

const availableCurriculums = ref([]);
const preferredCurriculum = ref();
const test = ref("test");
const axios = inject("axios");

async function getAvailableCurriculums() {
  await axios
    .get("/api/v3/curriculums/available-curriculums")
    .then(({ data }) => {
      availableCurriculums.value = data;
    });
}

function syncPreferredCurriculum() {
  if (availableCurriculums.value.length === 2) {
    // Find the index of selected curriculum in the array
    const curriculumIndex = availableCurriculums.value.findIndex(
      (curriculum) => curriculum.id === preferredCurriculum.value
    );

    // If curriculum exists in the array, get the opposite
    if (curriculumIndex !== -1) {
      // Get the index of the opposite curriculum
      const oppositeIndex = curriculumIndex === 0 ? 1 : 0;

      // Get the opposite curriculum from the array
      preferredCurriculum.value = availableCurriculums.value[oppositeIndex].id;
    }
  }
  axios
    .patch("/api/v3/curriculums/update/preferred-curriculum", {
      curriculum_id: preferredCurriculum.value,
    })
    .then(({ data }) => {
      eventBus.$emit("reset-course-list");
    });
}

function getPreferredCurriculum() {
  axios.get("/api/v3/curriculums/preferred").then(({ data }) => {
    preferredCurriculum.value = data;
  });
}

getPreferredCurriculum();

getAvailableCurriculums();

</script>