import Bugsnag from "@bugsnag/js";
import { ref } from "vue";
import { useRouter } from "vue-router";

export const errorHandler = (error) => {
    const errorObject = ref(null)
    const router = useRouter();
    switch (error.response.status) {
        case 422:
            errorObject.value = error.response.data
            break;
        
        case 401:
            router.push({ name: 'PageNotFound'})
            break;
        
        case 404:
            router.push({ name: 'PageNotFound'})
            break;
    
        default:
            Bugsnag.notify(error)
            break;
    }

    return {
        data: errorObject.value,
        error
    }
}