<template>

  <div class="animate-pulse">
    <div class="border-b-4 border-untitled-gray-800 mx-3 mt-2 cursor-pointer">
      <div class="h-48 w-full bg-black bg-opacity-25 relative">
        <img
            src="https://via.placeholder.com/800x450?text=Thumbnail%20Loading..."
            class="w-full h-full object-cover object-center"
        />
        <div
            class="absolute md:w-[100%] bottom-0 h-full object-center inset-0 place-items-center bg-gradient-to-t from-black opacity-50 text-white text-xs text-center leading-4"
        >
          <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="mt-[18%] object-center place-items-center inset-0 mx-auto w-20 h-20"
          >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
            />
          </svg>
        </div>
      </div>
      <div class="min-h-[140px] max-h-[140px]">
        <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mt-4 mb-4"></div>
        <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2"></div>
        <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2"></div>
        <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SkeletonPlaylist"
}
</script>

<style scoped>

</style>