import { defineStore } from "pinia";

export const useBreadcrumbsStore = defineStore("breadcrumbStore", {
  state: () => ({
    breadcrumbs: [
      {
        name: "Home",
        route: {
          name: "home",
        },
        description: ''
      },
    ],
  }),
  actions: {
    setBreadcrumbs(route) {
      let routeData = {
        name: route.meta.title,
        route: {
          name: route.name,
          params: route.params,
        },
      };
      if (!routeData.name) return;

      if (this.checkIfExist(routeData)) return;
      this.breadcrumbs.push(routeData);
    },
    checkIfExist(route) {
      let page = this.breadcrumbs.find((page) => page.name == route.name);

      if (!page) return false;

      this.breadcrumbs.map((page) => {
        if (page.name == route.name) {
          page.route = route.route;
        }

        return page;
      });

      return true;
    },
    clickRoute(routeData) {
        let index = this.breadcrumbs.findIndex((page) => page.name == routeData.name)
        if (index !== -1 && index < this.breadcrumbs.length - 1) { // check if the value exists in the array and is not the last element
            this.breadcrumbs.splice(index + 1); // remove all values after the selected value
        }
    },
    addDescription(route, description) {
      this.breadcrumbs.map((breadcrumb) => {
        if(breadcrumb.name == route.meta.title) {
          breadcrumb.description = description
        }

        return breadcrumb;
      })
    }
  },
});
