<template>
  <global-header v-if="$auth.check()"></global-header>
  <div class="min-h-screen">
    <global-breadcrumbs v-if="$auth.check()"/>
    <router-view/>
  </div>
  <global-footer v-if="$auth.check()"></global-footer>
  <notification-dialog
    v-if="notification.show"
    :content="notification.message"
    :icon="notification.icon"
    @close="notification.show = false"
  ></notification-dialog>
</template>

<script setup>
import {inject,onMounted, ref} from 'vue'
import Echo from "laravel-echo";
import { useAuth } from "@websanova/vue-auth/src/v3.js";

window.Pusher = require('pusher-js');

const auth = useAuth();
const axios = inject('axios');
const pusherKey = ref('');

onMounted(async () => {
   await setEnv()
   setupPusher()
});
const setEnv = async () => {
 await axios.get('/api/get-env').then(({ data }) => {
    pusherKey.value = data.pusher_key
  });
}
function setupPusher() {
  window.Echo = new Echo({
      broadcaster: 'pusher',
      key: pusherKey.value,
      cluster: 'us3',
      forceTLS: true,
      authEndpoint: '/broadcasting/auth',
      encrypted: true,
      auth: {
        headers: {
          Authorization: `Bearer ${auth.token()}`,
        },
      },
    });
}
</script>

<script>
import GlobalHeader from "./components/layout/GlobalHeader.vue";
import GlobalFooter from "./components/layout/GlobalFooter.vue";
import NotificationDialog from "@/components/partials/NotificationDialog";
import eventBus from "./plugins/eventBus";
import { useCookies } from "vue3-cookies";
import GlobalBreadcrumbs from './components/layout/GlobalBreadcrumbs.vue';

export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  components: {
    GlobalHeader,
    GlobalFooter,
    NotificationDialog,
    GlobalBreadcrumbs
},
  data(){
    return{
      notification: {
        message: "",
        show: false,
        icon: true,
      },
    };
  },
  mounted() {
    eventBus.$on("NOTIFICATION_DIALOG", (data) => {
      this.notification = {
        show: true,
        message: data.message,
        icon: data.icon,
      };
    });

    this.centralRefresh();
    setInterval(() => {
      if (this.$auth.check()) {
        this.centralRefresh();
      }
    }, 60000 * 30);
  },
  methods: {
    centralRefresh() {
      this.$http
        .post("/api/central/refresh", {
          central_token: this.cookies.get("current_central_token"),
        })
        .then(({ data }) => {
          if (process.env.VUE_APP_DISABLE_SSO === undefined) {
            if (!data) {
              this.$auth.logout();
            }
          }
        });
    },
  },
};
</script>

<style>
:root {
  --el-color-primary: #939495 !important;
}
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: #eaecf0;
  border-radius: 4px;
  margin: 10px 0;
}
::-webkit-scrollbar-thumb {
  background: #667085;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background: #667085;
}
.c2::-webkit-scrollbar-track {
  background-color: "#101828";
}
.dp__pm_am_button {
  color: black !important;
}
.el-select-v2__wrapper {
  padding: 5px !important;
}
.el-dialog {
  border-radius: 20px !important; 
}
.el-date-editor.el-input, .el-date-editor.el-input__wrapper {
  width: 100% !important;
}
.el-dialog__body{
  padding: 0 !important
}

.el-range-editor.el-input__wrapper {
  padding: 5px !important;
  width: 100% !important;
}
</style>