<template>
  <el-dialog v-if="value" v-model="value" width="30%" :show-close="false">
    <template #header>
      <h4 class="font-bold">New Event</h4>
    </template>
    <div class="lg:h-auto md:h-auto">
      <v-form @submit="saveEvent" class="flex flex-col gap-3 px-6 py-6">
        <div class="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-4">
          <div>
            <label class="mb-2 text-sm">Name</label>
            <v-text-field placeholder="Name" v-model="event.name" name="eventName"
              :disabled="isLoading" />
            <ErrorSpan class="text-xs" v-if="errorData && errorData.name">
              {{ errorData.name[0] }}
            </ErrorSpan>
          </div>

          <div>
            <label class="mb-2 text-sm">URL</label>
            <v-text-field placeholder="URL" v-model="event.url" name="url" :disabled="isLoading" />
            <ErrorSpan class="text-xs" v-if="errorData && errorData.url">
              {{ errorData.url[0] }}
            </ErrorSpan>
          </div>
        </div>

        <div>
          <label class="mb-2 text-sm">Timezone</label>
            <TimezoneSelector v-model="event.timezone"/>
            <ErrorSpan class="text-xs" v-if="errorData && errorData.timezone">
              {{ errorData.timezone[0] }}
            </ErrorSpan>
        </div>

        <div>
          <label class="mb-2 text-sm">Description</label>
          <v-textarea v-model="event.description" placeholder="Description" :disabled="isLoading" maxlength="255"
            name="description" :loading="isLoading">
          </v-textarea>
            <ErrorSpan class="text-xs" v-if="errorData && errorData.description">
              {{ errorData.description[0] }}
            </ErrorSpan>
        </div>

        <div class="w-full z-50 relative">
          <label class="mb-2 text-sm">Start Date and End Date Time</label>
          <DateRangePicker name="eventDate" class="z-50" v-model="dateRange" :format="'MMM D, YYYY h:mm A'" :value-format="'YYYY-MM-DD h:mm A'"
            :use-time-now="true" :error-message="errorDateRange"
            :editable="false"
            :teleported="true"/>
            <ErrorSpan class="text-xs" v-if="errorData && errorData.start_at && errorData.end_at">
              Start date and end date time is required.
            </ErrorSpan>
            <ErrorSpan class="text-xs" v-if="errorData && !errorData.start_at && errorData.end_at">
              {{ errorData.end_at[0] }}
            </ErrorSpan>
        </div>

        <div v-if="isSuperAdmin" class="static z-30">
          <label class="mb-2 text-sm">Companies</label>
          <ECombobox v-model="selectedCompanies" name="companies" :options="companies" option-name="name" value-key="id"
            :multiple="true" :filterable="true" :clearable="true" :disabled="companyLoading"
            placeholder="Start typing to search companies...." />
        </div>
        <div v-if="(isSuperAdmin || isAccountManager) && isNotSingleRooftop && filteredDealers.length" class="static z-20">
          <label class="mb-2 text-sm">Dealers</label>
          <ECombobox v-model="selectedDealers" name="dealers" :options="filteredDealers" option-name="name" value-key="id"
            :multiple="true" :filterable="true" :clearable="true"
            :disabled="isLoading"
            placeholder="Start typing to search dealers...." />
        </div>

        <div class="static z-10">
          <label class="mb-2 text-sm">Users</label>
          <ECombobox v-model="selectedAssignees" name="users" :options="users" option-name="name" value-key="id"
            :multiple="true" :filterable="true" :clearable="true" :disabled="isLoading"
            placeholder="Start typing to search users...." />
        </div>
        <div class="mt-5 w-full flex justify-end">
          <div class="w-auto flex">
            <v-button class="mr-3" type="submit" :loading="isLoading">
              Save
            </v-button>
            <v-button type="button" @click.prevent="closeModal" :loading="isLoading">
              Cancel
            </v-button>
          </div>
        </div>
      </v-form>
    </div>
  </el-dialog>
  <NotificationDialog v-if="showNotificationDialog" @close="showNotificationDialog = false" />
</template>
<script setup>
import NotificationDialog from "@/components/partials/NotificationDialog.vue";
import {
  VForm,
  VTextField,
  VTextarea,
  VButton,
} from "revdojo-vue-components";
import { debounce as _debounce } from 'lodash-es'
import ECombobox from "@/components/element-components/ECombobox"

import dayjs from "dayjs";
import eventBus from "@/plugins/eventBus";
import {
  ref,
  defineProps,
  defineEmits,
  inject,
  computed,
  onMounted,
  defineAsyncComponent,
  watch
} from "vue";
import { useAuthStore } from "@/stores";
import { getUsers } from "@/composable/users/getUsers";
import { ElDialog } from "element-plus";
import Bugsnag from "@bugsnag/js";
import { postEvent } from "@/composable/event/post-event";
import { postPayload } from '@/composable/event/event-payloads'
import ErrorSpan from "@/components/utilities/ErrorSpan.vue";
import TimezoneSelector from "@/components/utilities/TimezoneSelector.vue";

const DateRangePicker = defineAsyncComponent(() => import('@/components/element-components/DateRangePicker.vue'))

const axios = inject("axios");
const authUser = useAuthStore();
const { userList, users } = getUsers();

const props = defineProps({
  modelValue: {
    default: false,
    type: Boolean,
  },
  handleCloseModal: {
    type: Function,
  },
});

const emit = defineEmits(["update:modelValue"]);
const showNotificationDialog = ref(false);
const dealers = ref([]);
const companies = ref([]);
const timezones = ref([]);
const isLoading = ref(false);
const dateRange = ref([new Date, new Date]);

const startTime = ref(null)
const endTime = ref(null)

const userLoading = ref(false);
const timezoneLoading = ref(false);
const companyLoading = ref(false);

const event = ref({
  name: "",
  url: "",
  color: "blue",
  description: "",
  is_open: false,
  is_featured: false,
  assignees: null,
  timezone: null,
  password: "",
  companies: null,
  dealers: null,
});
const isSavingEvent = ref(false)

const params = ref({})

const selectedCompanies = ref([])
const selectedAssignees = ref([])
const selectedDealers = ref([])
const userProfile = computed(() => authUser.user)

const isSuperAdmin = computed(() => {
  const user = authUser.user;

  if (!user) {
    return false
  }

  return user.roles.some((role) => role.name === "super-administrator");

})

const isAccountManager = computed(() => {
  const user = authUser.user;
  if(!user) {
    return false
  }

  return user.roles.some((role) => role.name === "account-manager");
})

const isNotSingleRooftop = computed(() => {
  
  if(!userProfile.value) {
    return false;
  }

  if(!isSuperAdmin.value && userProfile.value && userProfile.value.dealer && userProfile.value.dealer.is_automotive) {
    return false;
  }

  return true
})

watch(selectedCompanies, () => {
  if (isSavingEvent.value) {
    return
  }
  selectedAssignees.value = []
  selectedDealers.value = []
})

watch(selectedDealers, () => {
  if (isSavingEvent.value) {
    return
  }
  selectedAssignees.value = []
})

const filteredDealers = computed(() => {
  if (dealers.value.length == 0) {
    return []
  }

  if (selectedCompanies.value && selectedCompanies.value.length) {
    return dealers.value.filter(dealer => selectedCompanies.value.includes(dealer.dealer_id))
  }

  return dealers.value
})

watch(event.value, async (val) => {
  if (val.companies && val.companies.length) {
    params.value.dealer_ids = val.companies
  }

  if (val.dealers && val.dealers.length) {
    params.value.specific_dealer_ids = val.dealers
  }
  await userList(params.value);
})

watch(selectedCompanies, async (val) => {
  let params = {};
  params.dealer_ids = val
  await userList(params);
})
watch(selectedDealers, async (val) => {
  let params = {};
  params.specific_dealer_ids = val
  if(selectedCompanies.value && selectedCompanies.value.length) {
    params.dealer_ids = selectedCompanies.value
  }
  await userList(params);
})

const dateFormat = "MM/DD/YY hh:mm A";
const errorDateRange = ref(null)
const errorData = ref(null)

const saveEvent = async () => {
  isSavingEvent.value = true
  isLoading.value = true;
  errorDateRange.value = null;
  errorData.value = null;

  if (!dateRange.value) {
    errorDateRange.value = 'Date and Time is required'
    isLoading.value = false
    isSavingEvent.value = false;
    return;
  }

  const tempEvent = postPayload(
    event.value,
    selectedAssignees.value,
    selectedDealers.value,
    selectedCompanies.value,
    dateRange.value
  );

  const { data: eventResponse, isError, errorValue } = await postEvent({...tempEvent})
  isLoading.value = false
  isSavingEvent.value = false
  if (isError && errorValue.value) {
    errorData.value = errorValue.value.errors
    return;
  }

  eventBus.$emit("NEW_EVENT", eventResponse);
  closeModal();
};

const closeModal = () => {
  event.value = {
    name: "",
    url: "",
    start_at: "",
    end_at: "",
    color: "blue",
    description: "",
    is_open: false,
    is_featured: false,
    assignees: [],
    timezone: null,
    password: "",
    companies: [],
    dealers: [],
  };
  dateRange.value = []
  selectedAssignees.value = []
  selectedCompanies.value = []
  selectedDealers.value = []

  emit("update:modelValue", false);
};

const getCompaniesAndDealers = async () => {
  companyLoading.value = true;

  await axios
    .get("/api/companies-and-dealers")
    .then(({ data }) => {
      dealers.value = data.data.dealers;
      companies.value = data.data.companies;
      filteredDealers.value = dealers.value
      companyLoading.value = false;
    })
    .catch((error) => {
      companyLoading.value = false;
      Bugsnag.notify(error);
    });
};

const getTimezones = () => {
  timezoneLoading.value = true;

  axios
    .get("api/v3/timezones", {
      params: {
        timezones: [
          "America/Chicago",
          "America/Denver",
          "America/Phoenix",
          "America/Los_Angeles",
          "America/Anchorage",
          "Pacific/Honolulu",
        ],
      },
    })
    .then(({ data }) => {
      timezones.value = data;
      timezoneLoading.value = false;
    })
    .catch((error) => {
      Bugsnag.notify(error);
    }).finally(() => {
      timezoneLoading.value = false;
    });
};

const filterUsers = _debounce(async (params) => {
  userLoading.value = true

  userList(params).then(() => {
    userLoading.value = false
  })
    .catch((error) => {
      Bugsnag.notify(error);
      userLoading.value = false
    });
}, 1000)


const value = computed({
  get() {
    return props?.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const getCurrentHourTime = async (addHour = 0) => {
  const now = new Date();
  let hour = now.getHours() + addHour;
  let minute = now.getMinutes();
  let roundedMinute = 15 * Math.round(minute / 15);


  if (minute >= 60) {
    minute = 0;
    hour++;
  }

  if (roundedMinute == 60) {
    roundedMinute = 0;
    hour++;
  }


  if (hour >= 12) {
    hour = hour - 12;
  }

  if (hour == 0) {
    hour = 12;
  }

  roundedMinute = roundedMinute.toString().padStart(2, "0")

  const period = now.getHours() + addHour >= 12 ? "PM" : "AM"

  return await `${hour.toString().padStart(2, "0")}:${roundedMinute.toString().padStart(2, "0")} ${period}`
}

const timeOptions = computed(() => {
  const now = new Date();
  const start_time = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)

  const time = []

  while (start_time.getDate() === now.getDate()) {
    time.push({ name: start_time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), value: start_time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) })
    start_time.setTime(start_time.getTime() + 15 * 60 * 1000)
  }

  return time
})

const endTimeOptions = computed(() => {

  if (!timeOptions.value.length) {
    return []
  }

  if (isDateRangeEqual.value) {
    const startTimeIndex = timeOptions.value.findIndex((item) => (item.value === startTime.value))

    return timeOptions.value.slice(startTimeIndex, timeOptions.value.length)
  }

  return timeOptions.value
})


const isDateRangeEqual = computed(() => {

  if (!dateRange.value) {
    return false
  }

  let startDate = dayjs(dateRange?.value[0]).format(dateFormat)
  let endDate = dayjs(dateRange?.value[1]).format(dateFormat)

  return startDate === endDate
})

watch(startTime, (current, prev) => {
  const currentIndex = timeOptions.value.findIndex((item) => (item.value === current))
  const prevIndex = timeOptions.value.findIndex((item) => (item.value === prev))
  const timeIndex = endTimeOptions?.value.findIndex((item) => (item.value === endTime.value))

  const total = Math.abs(prevIndex - currentIndex)

  if (currentIndex < prevIndex) {
    const itemIndex = Math.abs(timeIndex - total)
    const index = total > timeIndex ? Math.abs(endTimeOptions?.value.length - itemIndex) : itemIndex

    endTime.value = endTimeOptions?.value[index]?.value
  } else {
    if (isDateRangeEqual.value && currentIndex >= timeOptions.value.length - 1) {
      return endTime.value = endTimeOptions?.value[endTimeOptions?.value.length - 1].value
    }

    const itemIndex = Math.abs(timeIndex + total)
    const index = itemIndex > endTimeOptions?.value.length ? Math.abs(endTimeOptions?.value.length - itemIndex) : itemIndex

    endTime.value = endTimeOptions?.value[index]?.value
  }

})

watch(() => event.value.companies, () => {
  const userParams = {
    dealer_ids: event.value.companies,
    specific_dealer_ids: event.value.dealers,
  }

  if (event?.value?.companies?.length) {
    filteredDealers.value = dealers?.value?.filter((dealer) => {
      return event?.value?.companies?.find((company) => (company === dealer?.dealer_id))
    })
  } else {
    filteredDealers.value = dealers.value
  }
  filterUsers(userParams)
})

watch(() => event.value.dealers, () => {
  const params = {
    dealer_ids: event?.value?.companies,
    specific_dealer_ids: event?.value?.dealers,
  }
  filterUsers(params)
})


onMounted(async () => {
  userLoading.value = true;


  startTime.value = await getCurrentHourTime()
  endTime.value = await getCurrentHourTime(1)

  getCompaniesAndDealers();
  getTimezones();
  await userList();
  userLoading.value = false;
});

watch(props, () => {
  if(!props.modelValue) {
    errorData.value = null;
  }
})
</script>