import aws from 'aws-sdk'


aws.config.update({
  secretAccessKey: 'K2z0/Ehq+aRE2sftCbXFk+hTszAbwjnWVSJblZJs',
  accessKeyId: 'AKIA5EWLLCAYEH2Z7J7K'
})

export const s3 = new aws.S3({
    region: 'us-east-1',
})

